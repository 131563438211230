<template>
  <div class="misc-wrapper error-404">
    <b-link
      class="brand-logo d-flex align-items-center"
    >
      <b-img
        fluid
        alt="img"
        :class="appName ? 'logo-img' : 'logo-img-full'"
        :src="logo"
      />
      <h6 class="mb-0 app-name">
        {{ appName }}
      </h6>
    </b-link>
    <div
      v-if="loading"
      class="misc-inner p-2 p-sm-3"
    >
      <b-spinner
        variant="secondary"
        style="margin-right: 10px"
      />
      Connecting...
    </div>
    <div
      v-if="hasError || isFailPage"
      class="d-flex align-items-center"
    >
      <div class="warning-message d-flex align-items-center">
        <img
          class="alert-img mr-1"
          alt="alert"
          :src="require('@/assets/images/common/ic-alert.svg')"
        >
        <span>
          Error: {{ message }}
        </span>
      </div>
    </div>
    <div
      v-else-if="confirmEmail"
      class="d-flex align-items-center"
    >
      <div class="warning-message d-flex align-items-center">
        <b-img
          rounded
          class="mr-50"
          :src="require('@/assets/images/pages/overview/ic-success.png')"
          style="width: 30px"
        />
        <h5 class="mb-0">
          A verification link has been sent to {{ confirmEmail }}. Please open mail box and check it!
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require,camelcase */
import { BLink, BImg, BSpinner } from 'bootstrap-vue'
import envMixin from '@/mixins/envMixin'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')

export default {
  components: {
    BLink,
    BSpinner,
    BImg,
  },
  mixins: [envMixin],
  data() {
    return {
      downImg: require('@/assets/images/pages/error-404.png'),
      hasError: false,
      confirmEmail: null,
    }
  },
  computed: {
    ...mapGetters(['status', 'loading', 'message', 'shopifyVerified']),

    appName() {
      return process.env.VUE_APP_LOGO_NAME
    },

    isFailPage() {
      return this.$route?.query?.status === 'failure'
    },
  },
  created() {
    this.checkConnectFromShopify()
  },
  methods: {
    ...mapActions(['verifyShopifyConnect']),

    async checkConnectFromShopify() {
      this.hasError = false
      this.confirmEmail = null

      const queries = this.$route?.query
      let params = null

      Object.keys(queries).forEach(key => {
        params = { ...params, [key]: queries[key] }
      })

      if (params) {
        const { email } = params

        if (email) {
          this.confirmEmail = email
        } else {
          await this.verifyShopifyConnect(params)

          if (this.status) {
            const { shopifyVerified } = this
            const { redirectUrl } = shopifyVerified
            if (redirectUrl) {
              window.location.href = redirectUrl
            }
          } else {
            this.hasError = true
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
<style scoped lang="scss">
  .logo-img{
    max-width: 50px;
  }

  .logo-img-full {
    width: 135px;
  }

  .app-name{
    margin-left: .5rem;
    max-width: 100px;
  }

  .alert-img {
    width: 18px;
  }

  .warning-message {
    margin-top: 10px;
    background: rgba(251, 176, 59, 0.1);
    border-radius: 10px;
    padding: 15px 12px 15px 16px;
  }
</style>
